<template>
  <div class="d-flex align-items-center justify-content-center h-100">
    <b-card no-body style="flex: 0.5">
      <b-card-header header-bg-variant="transparent" class="d-flex justify-content-end">
        <b-button variant="light" class="mr-2" size="sm" @click="resetForm">{{
          $t("CLEAR")
        }}</b-button>
        <b-button class="text-white" @click="$router.push({ name: 'RolesList' })">
          {{ $t("BACK") }}
        </b-button>
      </b-card-header>
      <b-card-body>
        <b-form @submit.prevent="editFormSubmit">
          <b-form-group label-cols-lg="3" :label="$t('ROLE.label')" label-size="sm">
            <b-form-input
              :placeholder="$t('ROLE.label')"
              aria-describedby="editRoleLabelFeedback"
              :state="validateState('label')"
              v-model="$v.form.label.$model"
            />
            <b-form-invalid-feedback id="editRoleLabelFeedback">
              {{ $t("VALID.required", { name: $t("ROLE.label") }) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label-cols-lg="3" lable-size="sm" :label="$t('ROLE.name')">
            <b-input-group prepend="ROLE_">
              <b-form-input
                placeholder="Techniczna nazwa"
                v-model="$v.form.name.$model"
                :state="validateState('name')"
                aria-describedby="editRoleNameFeedback"
              />
              <b-form-invalid-feedback id="editRoleNameFeedback">
                {{ $t("VALID.required", { name: $t("ROLE.name") }) }}
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
          <b-form-group label-cols-lg="3" lable-size="sm" :label="$t('ROLE.duration')">
            <time-range-input
              v-model="form.duration"
              :state="form.duration ? true : null"
              :reset="resetTimeRangeInput"
              deselectLabel=""
              :default-value="defaultValueDuration"
              :allow-empty="false"
            />
          </b-form-group>
          <b-form-group label-cols-lg="3" lable-size="sm" :label="$t('ROLE.isActive')">
            <b-checkbox
              switch
              class="mt-1"
              v-model="$v.form.isActive.$model"
              :checked="form.isActive"
              aria-describedby="editRoleActiveFeedback"
            />
            <b-form-invalid-feedback id="editRoleActiveFeedback"
              >Checkbox jest wymagany</b-form-invalid-feedback
            >
          </b-form-group>

          <b-button
            type="submit"
            variant="primary"
            class="text-white ml-auto d-block"
            :disabled="$v.form.$anyError"
          >
            Wyślij
          </b-button>
        </b-form>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import required from "vuelidate/lib/validators/required";
import { mapGetters } from "vuex";
import TimeRangeInput from "@/components/TimeRangeInput/TimeRangeInput.vue";
import { EDIT_ROLE } from "@/core/store/Roles/role.module";
import { GET_ROLE } from "@/core/store/Roles/roles.module";

export default {
  name: "editRoleForm",
  components: { TimeRangeInput },
  data() {
    return {
      form: {
        id: this.$route.params.id,
        label: null,
        name: null,
        isActive: null,
        duration: null,
      },
      defaultValueDuration: null,
      resetTimeRangeInput: false,
    };
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {};
      this.resetTimeRangeInput = !this.resetTimeRangeInput;
    },
    editFormSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.form.name = `ROLE_${this.form.name}`;
      this.$store
        .dispatch(EDIT_ROLE, this.form)
        .then(() => {
          this.toastSuccess("Edit role success", "SUCCESS");
          this.$v.form.$reset();
        })
        .catch((err) => {
          this.toastError(err.data.message, "ERROR");
          this.form.name = "";
        });
    },
    setFormValue(data) {
      this.form.id = data.id;
      this.form.isActive = data.isActive;
      this.form.label = data.label;
      this.form.name = data.name.replace("ROLE_", "");
      this.form.duration = data.duration;
      this.defaultValueDuration = data.duration;
    },
  },
  validations: {
    form: {
      label: {
        required,
      },
      name: {
        required,
      },
      isActive: {
        required,
      },
      duration: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(["getRole"]),
  },
  mounted() {
    if (!this.$route.params.role) {
      this.$store.dispatch(GET_ROLE, this.$route.params.id).then((resp) => {
        const { data } = resp;
        this.setFormValue(data);
      });
    } else {
      this.setFormValue(this.$route.params.role);
    }
  },
};
</script>
